<template>
 <sdPageHeader title="Hydromx"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #address>
          <sdCards title="Adres selecteren">
           <!--           <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">-->
           <!--            <line :x2="(Math.cos(-45 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(-45 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            <line :x2="(Math.cos(0 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(0 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            &lt;!&ndash; If you do not specify the stroke-->
           <!--                 color the line will not be visible &ndash;&gt;-->
           <!--           </svg>-->
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <div class="create-account-form">
              <a-form
               :model="addressForm"
               :rules="addressFormRules"
               layout="vertical"
               name="account"
               @finish="globalSearch"
              >
               <a-row :gutter="30">
                <a-col :sm="10" :xs="24">
                 <a-form-item label="Postcode" name="postalCode">
                  <a-input v-model:value="addressForm.postalCode" placeholder=""/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item label="Huisnummer" name="houseNumber">
                  <a-input v-model:value="addressForm.houseNumber"
                           onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder="" type="number"/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                              name="houseNumberAddition"
                 >
                  <a-select v-model:value="addressForm.houseNumberAddition" :allowClear="true"
                            class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                    :key="index"
                                    :value="item.value" name="energyAllowSingleFuelElectricity">
                    {{ item.key }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24">
                 <a-form-item label="&nbsp;">
                  <a-button :disabled="disabledGlobalSearchButton||globalSearchLoading||loadingHouseNumberAdditions"
                            :loading="globalSearchLoading||loadingHouseNumberAdditions" class="btn-signin w-100"
                            html-type="submit"
                            size="large"
                            type="primary">
                   Zoeken
                  </a-button>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </a-col>
           </a-row>
          </sdCards>
          <a-row v-if="globalSearchData && globalSearchData.address" :gutter="30">
           <a-col :md="14">
            <sdCards title="Klant selecteren">
             <a-row style="padding: 0 !important;">
              <a-col :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Nieuwe klanten</sdHeading>
                <a-row :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;">
                 <a-col :xl="16" :xs="24"
                        class="p-2 mb-2" style="vertical-align: middle;display: table-cell;align-self: center">
                  {{ globalSearchData.address.street }}
                  {{
                   globalSearchData.address.houseNumber
                  }} {{
                   globalSearchData.address.houseNumberAddition ? globalSearchData.address.houseNumberAddition : ''
                  }}<br/>
                  {{ globalSearchData.address.postalCode }} {{ globalSearchData.address.city }} <br/>Nederland
                 </a-col>
                 <a-col :xl="8" :xs="24" justify="end"
                        style="vertical-align: middle;align-self: center;text-align: end">
                  <a-button :disabled="loadingPreFillRelationOnOrder"
                            :loading="loadingPreFillRelationOnOrder"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'CONSUMER')">
                   Doorgaan als particulier
                  </a-button>
                  <a-button :disabled="loadingPreFillRelationOnOrder"
                            :loading="loadingPreFillRelationOnOrder"
                            class="mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'BUSINESS')">
                   Doorgaan als zakelijk
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.relations.length > 0" justify="center">
              <a-col :sm="24" :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Bestaande klanten</sdHeading>
                <a-row v-for="relation in globalSearchData.relations" :key="relation.id" :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                 <a-col :xl="18" :xs="24" style="vertical-align: middle;display: table-cell;align-self: center;">
                                          <span><strong>{{ relation.fullName }}</strong>
                                                                                      <span class="status-badge ml-2">{{
                                                                                        relation.type && relation.type === 'BUSINESS' ? 'Zakelijk' : 'Particulier'
                                                                                       }}</span>
                                          </span><br/>
                  <span>{{ relation.street }} {{
                    relation.houseNumber
                   }} {{
                    relation.houseNumberAddition ? relation.houseNumberAddition : ''
                   }}<br/> {{ relation.postalCode }} {{ relation.city }}<br/> Nederland</span><br/>
                 </a-col>
                 <a-col :xl="6" :xs="24"
                        style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                  <a-button :disabled="loadingPreFillRelationOnOrder"
                            :loading="loadingPreFillRelationOnOrder"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(relation,relation.type)"> Selecteren
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.coc.length > 0" ustify="center">
              <a-col :sm="24" :xs="24" class="mb-2">
               <sdHeading as="h6" class="mb-5" style="font-size:14px">Gevonden in<img
                :src="require(`@/static/img/kvk.svg`)"
                alt="" class="ml-5"/></sdHeading>
               <a-row v-for="coc in globalSearchData.coc" :key="coc.companyCocNumber" :gutter="30"
                      style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                <a-col :xl="18" :xs="24"
                       class="p-2" style="vertical-align: middle;display: table-cell;align-self: center">
                                          <span><strong>{{ coc.companyName }} - {{
                                            coc.companyCocNumber
                                           }}</strong></span><br/>
                 <span>{{ coc.street }} {{
                   coc.houseNumber
                  }} {{
                   coc.houseNumberAddition ? coc.houseNumberAddition : ''
                  }}<br/> {{ coc.postalCode }} {{ coc.city }}<br/>Nederland</span>
                </a-col>
                <a-col :xl="6" :xs="24" justify="end"
                       style="vertical-align: middle;align-self: center;text-align: end">
                 <a-button :disabled="loadingPreFillRelationOnOrder"
                           :loading="loadingPreFillRelationOnOrder"
                           class="mr-2 mb-2 mt-2 w-100"
                           size="large"
                           type="primary-outlined"
                           @click.prevent="handleSelectRelation(coc,'BUSINESS')"> Doorgaan als zakelijk
                 </a-button>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
           </a-col>
           <a-col :md="10">
            <sdCards style="text-align: center" title="Locatie">
             <img :src="globalSearchData.streetViewImageFileURL" style="width: 100%;height: auto;border-radius:5px">
            </sdCards>
           </a-col>
          </a-row>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <router-link to="/order/flow">
                 <sdButton class="btn-prev" to="" type="primary">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </router-link>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #questions>
          <a-form :model="productQuestionForm" :rules="questionRules" layout="vertical"
                  @finish="handleProductQuestions">
           <sdCards title="Productvragen">
            <a-row :gutter="30">
             <a-col :sm="6" :xs="24" class="mb-25">
              <a-form-item label="Type cv-installatie" name="type">
               <a-select v-model:value="productQuestionForm.type" :allowClear="true"
                         class="sDash_fullwidth-select"
                         name="type"
                         size="large" @change="handleTypeChange($event)">
                <a-select-option value="CV-ketel">
                 CV-ketel
                </a-select-option>
                <a-select-option value="Warmtepomp">
                 Warmtepomp
                </a-select-option>
                <a-select-option value="Hybride warmtepomp">
                 Hybride warmtepomp
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="6" :xs="24" class="mb-25">
              <a-form-item label="Gasverbruik op jaarbasis (geschat m3)" name="usage">
               <a-input v-model:value="productQuestionForm.usage"
                        name="usage"
                        onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                        onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                        placeholder=""
                        type="number"/>
              </a-form-item>
             </a-col>
             <a-col :sm="6" :xs="24" class="mb-25">
              <a-form-item label="Aantal radiatoren in woning" name="radiators">
               <a-input v-model:value="productQuestionForm.radiators"
                        name="radiators"
                        onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                        onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                        placeholder=""
                        type="number"/>
              </a-form-item>
             </a-col>
             <a-col :sm="6" :xs="24" class="mb-25">
              <a-form-item label="Aantal m2 vloerverwarming" name="underFloorHeating">
               <a-input v-model:value="productQuestionForm.underFloorHeating"
                        name="underFloorHeating"
                        onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                        onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                        placeholder=""
                        type="number"/>
              </a-form-item>
             </a-col>
            </a-row>
           </sdCards>
           <ActionWrapper>
            <div class="step-action-wrap">
             <div class="step-action-inner">
              <a-row>
               <a-col :xs="24">
                <div class="steps-action">
                 <sdButton class="btn-prev" type="primary" @click.prevent="() => prevEan()">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                 <a-button :disabled="loadingCalculatePrice"
                           :loading="loadingCalculatePrice" class="btn-next"
                           html-type="submit"
                           type="primary">
                  <span>Volgende</span>
                  <sdFeatherIcons size="16" type="arrow-right"/>
                 </a-button>
                </div>
               </a-col>
              </a-row>
             </div>
            </div>
           </ActionWrapper>
          </a-form>

         </template>
         <template #relation>
          {{ order.relation }}
          <Relation
           :key="relKey"
           ref="relationComponentRef"
           :disable-is-business="true"
           :is-order-create="true"
           :maxAge="80"
           :minAge="18"
           :relation="order.relation"
           :show-card-save-button="false"
           :show-footer="false"
           :show-global-save-button="true"
           :showHeader="false"
           type="ADD"
           @submit-external="handleRelationOnOrder($event)"
          ></Relation>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Vorige</span>
                </sdButton>
                <sdButton class="btn-next" type="primary"
                          @click.prevent="$refs.relationComponentRef.submitExternal()">
                 <span>Volgende</span>
                 <sdFeatherIcons size="16" type="arrow-right"/>
                </sdButton>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #summary>
          <Main>
           <a-row :gutter="25">
            <a-col :md="12" :xs="24">
             <Suspense>
              <sdCards title="Product">
               <div class="card productRevision-card">
                <figure>
                 <img alt="" src="https://www.hydromx.com/wp-content/uploads/2021/09/hydromx_logo.png"
                      style="max-width: 200px"/>
                </figure>
                <figcaption>
                 <div class="card__content" style="margin-top:20px">
                  Hydromx® is speciaal ontwikkeld om cv-ketels, en (hybride) warmtepompen efficiënter te laten
                  werken.
                 </div>
                </figcaption>
               </div>
              </sdCards>
             </Suspense>
            </a-col>
            <a-col :md="12" :xs="24">
             <Suspense>
              <template #default>
               <RelationSummaryCard :order="order"/>
              </template>
             </Suspense>
            </a-col>
           </a-row>
           <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Ondertekenen">
              <a-row :gutter="25">
               <a-col :xs="12" class="signature align-center">
                <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'" :sigOption="sigState.option"
                               :w="'450px'"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                 <div role="alert">Uw handtekening is vereist</div>
                </div>
                <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                 <span>Opnieuw</span>
                </sdButton>
               </a-col>
               <!--               <a-col :xs="12" class="mb-5">-->
               <!--                <a-form ref="customerApprovalsForm" :model="order"-->
               <!--                        layout="vertical">-->
               <!--                 <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"-->
               <!--                              :name="['product','customerApprovalsValidation', index]"-->
               <!--                              :rules="{ validator: checkCheckBox }" class="checkbox-group"-->
               <!--                              label=""-->
               <!--                              style="padding-bottom:0px;margin-bottom:0px">-->
               <!--                  <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"-->
               <!--                              :name="['product','customerApprovalsValidation', index]">-->
               <!--                   {{ item }}-->
               <!--                  </a-checkbox>-->
               <!--                 </a-form-item>-->
               <!--                </a-form>-->
               <!--               </a-col>-->
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
          </Main>
          <ActionWrapper v-if="!isFinished">
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="light" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Terug</span>
                </sdButton>
                <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                          type="primary"
                          @click="handleCreateOrder"> Aanmaken
                </a-button>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateButtonWrapper,
 FigureWizards,
 OrderSummary,
 ProductTable,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {BasicFormWrapper, DatePickerWrapper, Main, ModalMain} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import ProductCards from "./component/ProductCards";
import FlowCards from "./component/FlowCards";
import {
 capacityCodeElectricityOptions,
 capacityCodeGasOptions,
 defaultOptions,
 meterTypeOptions,
 switchTypeOptions,
 usageEstimationOptions
} from "@/utility/enums";
import {ProductOverviewTable} from "./component/style";
import {ActionWrapper} from "@/components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "@/utility/validators";
import Aom from "../../components/aom/Aom";
import ProductInfoCard from "./component/ProductInfoCard";
import ProductSummaryInfoCard from "./component/ProductSummaryInfoCard";
import ConnectionTable from "./component/ConnectionTable";
import RelationSummaryCard from "./component/RelationSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRouter} from "vue-router";

const OrderCreateEnergy = {
 name: 'OrderCreateEnergy',
 components: {
  ConnectionTable,
  RelationSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  ProductTable,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ProductCards,
  ConnectionCardWrapper,
  ProductInfoCard,
  RelationCard,
  ProductOverviewTable,
  ActionWrapper,
  EstimateButtonWrapper,
  Aom,
  FlowCards,
  ProductSummaryInfoCard,
  DatePickerWrapper,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 watch: {
  addressForm: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.addressForm.postalCode,
       houseNumber: this.addressForm.houseNumber,
       country: this.addressForm.country,
       type: 'HYDROMX',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const relationComponentRef = ref();
  const houseNumberAdditionsOptions = computed(() => state.globalSearch.houseNumberAdditions);
  const globalSearchData = computed(() => state.globalSearch.data);
  const globalSearchLoading = computed(() => state.globalSearch.loadingSearch);
  const disabledGlobalSearchButton = computed(() => state.globalSearch.disabledGlobalSearchButton);
  const order = reactive(computed(() => state.hydromx.order));
  // const flows = computed(() => state.flows.flows);
  const productCalculations = computed(() => state.hydromx.productCalculations);
  const loadingCreateOrder = computed(() => state.hydromx.loadingCreateOrder);
  const loadingPreFillRelationOnOrder = computed(() => state.hydromx.loadingPreFillRelationOnOrder);
  const loadingCalculatePrice = computed(() => state.hydromx.loadingCalculatePrice);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const flowQuestionsForm = ref();
  const customerApprovalsForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const showMultiSiteDisabledError = ref(false);
  let relKey = ref(0);

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const productQuestionForm = reactive({
   type: '',
   usage: '',
   radiators: '',
   underFloorHeating: '',
  });

  const questionRules = {
   type: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   usage: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   radiators: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   underFloorHeating: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ]
  }
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'HYDROMX',
   });
  };

  const handleSelectRelation = async (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }
   if (type === 'CONSUMER') {
    relation.companyName = null;
    relation.companyCocNumber = null;
   }
   await dispatch('resetHydromxOrder');
   await dispatch('preFillRelationOnHydromxOrder', {value: relation, stepRoute: next});
  };

  const handleRelationOnOrder = (data) => {
   dispatch('addRelationToHydromxOrder', {value: data, stepRoute: next})
  };

  const handleProductQuestions = () => {
   dispatch('calculatePrice', {value: productQuestionForm, stepRoute: next})
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleCreateOrder = () => {
   const orderValidators = [];
   signatureError.value = false;
   // if (order.value.signatureType === 'SIGNATURE') {
   //  orderValidators.push(customerApprovalsForm.value.validate());
   // }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureData', signature1.value.save('image/svg+xml'))
      dispatch('createOrder', {successRoute: orderCreateSuccessRoute})
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createHydromxOrder', {successRoute: orderCreateSuccessRoute})
    }
   })
    .catch(() => dispatch('orderCreateValidationError'))
  };

  const showUsageEstimator = ref(false);
  const selectedEanToEstimateUsage = ref(null)
  const estimateUsage = (ean) => {
   selectedEanToEstimateUsage.value = ean;
   showUsageEstimator.value = true;
  };

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  const handleTypeChange = (event) => {
   productQuestionForm.type = event.target.value;
  };

  onMounted(async () => {
   await dispatch('resetCreateOrder');
  });

  return {
   sigState,
   steps: [
    {
     title: "Adres",
     content: "address",
    },
    {
     title: "Productvragen",
     content: "questions",
    },
    {
     title: "Klantgegevens",
     content: "relation",
    },
    {
     title: "Overzicht",
     content: "summary",
    },
   ],
   clearSignature,
   signature1,
   addressForm,
   addressConnectionForm,
   houseNumberAdditionsOptions,
   addressFormRules,
   globalSearchData,
   order,
   globalSearchLoading,
   disabledGlobalSearchButton,
   productCalculations,
   relationComponentRef,
   showUsageEstimator,
   switchTypeOptions,
   meterTypeOptions,
   defaultOptions,
   capacityCodeElectricityOptions,
   capacityCodeGasOptions,
   usageEstimationOptions,
   handleSelectRelation,
   globalSearch,
   done,
   prev,
   next,
   handleRelationOnOrder,
   handleCreateOrder,
   estimateUsage,
   isFinished,
   current,
   onHandleChange,
   onShowSizeChange,
   pageSize,
   form,
   moment,
   loadingPreFillRelationOnOrder,
   prevEan,
   flowQuestionsForm,
   customerApprovalsForm,
   checkCheckBox,
   signatureError,
   loadingCreateOrder,
   showMultiSiteDisabledError,
   loadingHouseNumberAdditions,
   relKey,
   integerValidator,
   questionRules,
   productQuestionForm,
   handleTypeChange,
   handleProductQuestions,
   loadingCalculatePrice,
  };
 },
};

export default OrderCreateEnergy;
</script>
<style>
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}
</style>
