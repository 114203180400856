<template>
 <sdPageHeader title="SVM Rapport"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #address>
          <sdCards title="Adres selecteren">
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <div class="create-account-form">
              <a-form
               :model="addressForm"
               :rules="addressFormRules"
               layout="vertical"
               name="account"
               @finish="globalSearch"
              >
               <a-row :gutter="30">
                <a-col :sm="10" :xs="24">
                 <a-form-item label="Postcode" name="postalCode">
                  <a-input v-model:value="addressForm.postalCode" placeholder=""/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item label="Huisnummer" name="houseNumber">
                  <a-input v-model:value="addressForm.houseNumber"
                           onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder="" type="number"/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                              name="houseNumberAddition"
                 >
                  <a-select v-model:value="addressForm.houseNumberAddition" :allowClear="true"
                            class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                    :key="index"
                                    :value="item.value" name="energyAllowSingleFuelElectricity">
                    {{ item.key }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24">
                 <a-form-item label="&nbsp;">
                  <a-button :disabled="disabledGlobalSearchButton||globalSearchLoading||loadingHouseNumberAdditions"
                            :loading="globalSearchLoading||loadingHouseNumberAdditions" class="btn-signin w-100"
                            html-type="submit"
                            size="large"
                            type="primary">
                   Zoeken
                  </a-button>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </a-col>
           </a-row>
          </sdCards>
          <a-row v-if="globalSearchData && globalSearchData.address && !globalSearchData.svmAvailable" class="w-100">
           <a-col :xs="24">
            <sdAlerts
             :closable="false"
             :outlined="false"
             :showIcon="true"
             description="Minimaal 1 zakelijke gebruiksdoel vereist, het opgegeven adres komt niet in aanmerking voor de SVM subsidie omdat de gebruiksdoel niet voldoet aan de minimale eisen."
             message=""
             type="info"
            />
           </a-col>
          </a-row>
          <sdCards v-if="globalSearchData && globalSearchData.address && globalSearchData.svmAvailable"
                   title="Klant selecteren">
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <ConnectionDetailsWrapper>
              <a-col class="about-connection-wrapper">
               <ConnectionCardWrapper headless>
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Nieuwe klanten</sdHeading>
                <CardWrapper>
                 <figcaption style="padding:10px">
                  <p class="mt-0 mb-0">
                   <a-row :gutter="30">
                    <a-col :xs="15"
                           style="vertical-align: middle;display: table-cell;align-self: center;">
                     {{ globalSearchData.address.street }}
                     {{
                      globalSearchData.address.houseNumber
                     }} {{
                      globalSearchData.address.houseNumberAddition ? globalSearchData.address.houseNumberAddition : ''
                     }},
                     {{ globalSearchData.address.postalCode }}
                     {{ globalSearchData.address.city }}, Nederland
                    </a-col>
                    <a-col :xs="9"
                           style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                     <!--                     <a-button :disabled="loadingPreFillRelationOnOrder" :loading="loadingPreFillRelationOnOrder"-->
                     <!--                               class="mr-2"-->
                     <!--                               size="large"-->
                     <!--                               type="primary-outlined"-->
                     <!--                               @click.prevent="handleSelectRelation(globalSearchData.address,'CONSUMER')">-->
                     <!--                      Doorgaan als particulier-->
                     <!--                     </a-button>-->
                     <a-button :disabled="loadingPreFillRelationOnOrder" :loading="loadingPreFillRelationOnOrder"
                               size="large"
                               type="primary-outlined"
                               @click.prevent="handleSelectRelation(globalSearchData.address,'BUSINESS')">
                      Doorgaan als zakelijk
                     </a-button>
                    </a-col>
                   </a-row>
                  </p>
                 </figcaption>
                </CardWrapper>
               </ConnectionCardWrapper>
              </a-col>
             </ConnectionDetailsWrapper>
            </a-col>
           </a-row>
           <a-row v-if="globalSearchData.relations.length > 0" justify="center">
            <a-col :sm="24" :xs="24">
             <ConnectionDetailsWrapper>
              <a-col class="about-connection-wrapper">
               <ConnectionCardWrapper headless>
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Bestaande klanten</sdHeading>
                <CardWrapper v-for="relation in globalSearchData.relations" :key="relation.id"
                             class="mb-5">
                 <figcaption style="padding:10px">
                  <p class="mt-0 mb-0">
                   <a-row :gutter="30">
                    <a-col :xs="15" style="vertical-align: middle;display: table-cell;align-self: center;">
                                          <span><strong>{{ relation.fullName }}</strong>
                                           <!--                                           <span class="status-badge ml-2">{{-->
                                           <!--                                            relation.type && relation.type === 'BUSINESS' ? 'Zakelijk' : 'Particulier'-->
                                           <!--                                           }}</span>-->
                                          </span><br/>
                     <span>{{ relation.street }} {{
                       relation.houseNumber
                      }} {{
                       relation.houseNumberAddition ? relation.houseNumberAddition : ''
                      }}, {{ relation.postalCode }} {{ relation.city }}, Nederland</span><br/>
                    </a-col>
                    <a-col :xs="9"
                           style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                     <a-button :disabled="loadingPreFillRelationOnOrder" :loading="loadingPreFillRelationOnOrder"
                               size="large"
                               type="primary-outlined"
                               @click.prevent="handleSelectRelation(relation,'BUSINESS')"> Selecteren
                     </a-button>
                    </a-col>
                   </a-row>
                  </p>
                 </figcaption>
                </CardWrapper>
               </ConnectionCardWrapper>
              </a-col>
             </ConnectionDetailsWrapper>
            </a-col>
           </a-row>
           <a-row v-if="globalSearchData.coc.length > 0" ustify="center">
            <a-col :sm="24" :xs="24">
             <ConnectionDetailsWrapper>
              <a-col class="about-connection-wrapper">
               <ConnectionCardWrapper headless>
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Gevonden in<img
                 :src="require(`@/static/img/kvk.svg`)"
                 alt="" class="ml-5"/></sdHeading>
                <CardWrapper v-for="coc in globalSearchData.coc" :key="coc.companyCocNumber"
                             class="mb-5">
                 <figcaption style="padding:10px">
                  <p class="mt-0 mb-0">
                   <a-row :gutter="30">
                    <a-col :xs="15"
                           style="vertical-align: middle;display: table-cell;align-self: center;">
                                          <span><strong>{{ coc.companyName }} - {{
                                            coc.companyCocNumber
                                           }}</strong></span><br/>
                     <span>{{ coc.street }} {{
                       coc.houseNumber
                      }} {{
                       coc.houseNumberAddition ? coc.houseNumberAddition : ''
                      }}, {{ coc.postalCode }} {{ coc.city }}, Nederland</span>
                    </a-col>
                    <a-col :xs="9"
                           style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                     <a-button :disabled="loadingPreFillRelationOnOrder" :loading="loadingPreFillRelationOnOrder"
                               size="large"
                               type="primary-outlined"
                               @click.prevent="handleSelectRelation(coc,'BUSINESS')"> Doorgaan als zakelijk
                     </a-button>
                    </a-col>
                   </a-row>
                  </p>
                 </figcaption>
                </CardWrapper>
               </ConnectionCardWrapper>
              </a-col>
             </ConnectionDetailsWrapper>
            </a-col>
           </a-row>
          </sdCards>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <router-link to="/order/flow">
                 <sdButton class="btn-prev" to="" type="primary">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </router-link>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #analyze>
          <div>
           <sdModal :onCancel="handleComponentEnergyAddModalCancel"
                    :visible="visibleComponentEnergyAddModal"
                    size="small"
                    title="Component toevoegen"
                    type="primary">
            <ModalMain>
             <div class="create-account-form">
              <a-form
               :model="selectedComponent"
               :rules="rulesAddComponent"
               layout="vertical"
               @finish="handleAddComponent"
              >
               <a-row :gutter="30">
                <a-col :xs="24">
                 <a-form-item
                  label="Omschrijving"
                  name="selectedComponent">
                  <a-select v-model:value="selectedComponent.selectedComponent" :allowClear="true"
                            class="sDash_fullwidth-select"
                            name="selectedComponent"
                            size="large">
                   <a-select-option v-for="(item,index) in svmEnergyCategoryOptions"
                                    :key="index"
                                    :value="JSON.stringify(item)" name="selectedComponent">
                    {{ item.description }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :xs="24">
                 <sdButton style="width:100%" type="primary">
                  <sdFeatherIcons size="16" type="plus"/>
                  Toevoegen
                 </sdButton>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </ModalMain>
           </sdModal>
           <sdModal :onCancel="handleComponentGasAddModalCancel"
                    :visible="visibleComponentGasAddModal"
                    size="small"
                    title="Component toevoegen"
                    type="primary">
            <ModalMain>
             <div class="create-account-form">
              <a-form
               :model="selectedComponent"
               :rules="rulesAddComponent"
               layout="vertical"
               @finish="handleAddComponent"
              >
               <a-row :gutter="30">
                <a-col :xs="24">
                 <a-form-item
                  label="Omschrijving"
                  name="selectedComponent">
                  <a-select v-model:value="selectedComponent.selectedComponent" :allowClear="true"
                            class="sDash_fullwidth-select"
                            name="selectedComponent"
                            size="large">
                   <a-select-option v-for="(item,index) in svmGasCategoryOptions"
                                    :key="index"
                                    :value="JSON.stringify(item)" name="selectedComponent">
                    {{ item.description }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :xs="24">
                 <sdButton style="width:100%" type="primary">
                  <sdFeatherIcons size="16" type="plus"/>
                  Toevoegen
                 </sdButton>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </ModalMain>
           </sdModal>
           <a-form ref="questionsForm" :model="order" layout="vertical" @finish="handleSaveAnalyze">
            <sdCards title="De-minimissteun">
             <a-row :gutter="25" class="w-100">
              <a-col :xs="24">
               <p>Overheden, zoals provincies en gemeenten, kunnen bedrijven steun verlenen tot € 200.000,-. Dit maximum
                geldt over een periode van drie belastingjaren. Deze steun, de-minimissteun, wordt dan niet gezien als
                staatssteun. De steun is zo minimaal dat het weinig tot geen effect heeft op het handelsverkeer tussen
                de Europese lidstaten.</p>
              </a-col>
             </a-row>
             <a-row :gutter="25" class="w-100">
              <a-col :md="6" :xs="24">
               <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="De-minimissteun ontvangen?"
                            name="minimisSupport">
                <a-select v-model:value="order.minimisSupport" :allowClear="true" class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option v-for="(item,index) in defaultOptions"
                                  :key="index"
                                  :value="item.value"
                                  name="hasSolar">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
             </a-row>
             <a-row v-if="order.minimisSupport !== null && order.minimisSupport === true" class="w-100">
              <a-col :xs="24">
               <sdAlerts
                :closable="false"
                :outlined="false"
                :showIcon="true"
                description="Klant komt niet in aanmerking voor ondersteuning bij de aanvrag van de SVM subsidie."
                message=""
                type="warning"
               />
              </a-col>
             </a-row>
            </sdCards>
            <sdCards v-if="order.minimisSupport !== null && order.minimisSupport === false"
                     title="Huidige elektriciteit en gas situatie">
             <a-row :gutter="25" class="w-100">
              <a-col :md="6" :xs="24">
               <a-form-item :name="['svmQuestions','energyTariff']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Elektriciteitsprijs">
                <a-input v-model:value="order.svmQuestions.energyTariff" :name="['svmQuestions','energyTariff']"
                         addon-after="per kWh"
                         addon-before="€"
                         onkeydown="if(event.key===','){event.preventDefault();}"
                         onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                         placeholder=""/>
               </a-form-item>
              </a-col>
              <a-col :md="6" :xs="24">
               <a-form-item :name="['svmQuestions','gasTariff']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Gassprijs">
                <a-input v-model:value="order.svmQuestions.gasTariff" :name="['svmQuestions','gasTariff']"
                         addon-after="per m3"
                         addon-before="€"
                         onkeydown="if(event.key===','){event.preventDefault();}"
                         onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                         placeholder=""/>
               </a-form-item>
              </a-col>
              <a-col :md="6" :xs="24">
               <a-form-item :name="['svmQuestions','hasSolar']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Heeft zonnepanelen?">
                <a-select v-model:value="order.svmQuestions.hasSolar"
                          :allowClear="true"
                          :name="['svmQuestions','hasSolar']"
                          class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option v-for="(item,index) in defaultOptions"
                                  :key="index"
                                  :name="['svmQuestions','hasSolar']"
                                  :value="item.value">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
              <a-col v-if="!order.svmQuestions.hasSolar" :md="6" :xs="24">
               <a-form-item :name="['svmQuestions','solarAvailable']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Zonnepanelen mogelijk?">
                <a-select v-model:value="order.svmQuestions.solarAvailable"
                          :allowClear="true"
                          :name="['svmQuestions','solarAvailable']"
                          class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option v-for="(item,index) in defaultOptions"
                                  :key="index"
                                  :name="['svmQuestions','solarAvailable']"
                                  :value="item.value">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
              <a-col v-if="!order.svmQuestions.hasSolar && order.svmQuestions.solarAvailable" :md="6" :xs="24">
               <a-form-item :name="['svmQuestions','roofSurface']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Dak oppervlakte">
                <a-input v-model:value="order.svmQuestions.roofSurface"
                         :name="['svmQuestions','roofSurface']"
                         addon-after="m2"
                         onkeydown="if(event.key===','){event.preventDefault();}"
                         onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                         placeholder=""/>
               </a-form-item>
              </a-col>
              <a-col :md="6" :xs="24">
               <a-form-item :name="['svmQuestions','hasSmartThermostat']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Heeft slimme thermostaat?">
                <a-select v-model:value="order.svmQuestions.hasSmartThermostat"
                          :allowClear="true"
                          :name="['svmQuestions','hasSmartThermostat']"
                          class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option v-for="(item,index) in defaultOptions"
                                  :key="index"
                                  :name="['svmQuestions','hasSmartThermostat']"
                                  :value="item.value">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
             </a-row>
            </sdCards>
            <sdCards v-if="order.minimisSupport !== null && order.minimisSupport === false"
                     title="Energieverbruik per onderdeel">
             <div v-for="(item,index) in order.svmQuestions.usages" :key="index">
              <a-row v-if="item.type === 'ENERGY'" :gutter="25"
                     class="w-100">
               <a-col :md="8" :xs="24">
                <a-form-item label="Omschrijving">
                 <a-input v-model:value="item.description" :disabled="true"/>
                </a-form-item>
               </a-col>
               <a-col :md="4" :xs="24">
                <a-form-item label="Type">
                 <a-input :disabled="true" :value="item.type === 'ENERGY'? 'Energie':'Gas'"/>
                </a-form-item>
               </a-col>
               <a-col :md="4" :xs="24">
                <a-form-item :name="['svmQuestions','usages', index, 'amount']"
                             :rules="{required: true,message: 'Dit veld is verplicht'}"
                             label="Aantal">
                 <a-input v-model:value="item.amount" :addon-after="item.unit"
                          :name="['svmQuestions','usages', index, 'amount']"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""
                          @input="item.usage = (item.amount * item.amountPerUnit).toFixed(0)"/>
                </a-form-item>
               </a-col>
               <a-col :md="4" :xs="24">
                <a-form-item :name="['svmQuestions','usages', index, 'usage']"
                             :rules="{required: true,message: 'Dit veld is verplicht'}"
                             label="Verbruik">
                 <a-input v-model:value="item.usage" :addon-after="item.type === 'ENERGY'? 'kWh':'m3'"
                          :name="['svmQuestions','usages', index, 'usage']"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col v-if="!item.default" :md="4" :xs="24">
                <a-form-item label=" ">
                 <sdButton class="ml-4" size="small" type="danger" @click.prevent="handleRemoveCategory(index)">
                  <sdFeatherIcons size="14" type="trash"/>
                 </sdButton>
                </a-form-item>
               </a-col>
              </a-row>
             </div>
             <a-row>
              <a-col :xs="24">
               <hr/>
              </a-col>
             </a-row>
             <a-row>
              <a-col :xs="24" class="mt-6">
               <sdButton class="ml-4" size="large" type="primary"
                         @click.prevent="visibleComponentEnergyAddModal = true">
                <sdFeatherIcons size="14" type="plus"/>
                Onderdeel toevoegen
               </sdButton>
              </a-col>
             </a-row>
            </sdCards>
            <sdCards v-if="order.minimisSupport !== null && order.minimisSupport === false"
                     title="Gasverbruik per onderdeel">
             <div v-for="(item,index) in order.svmQuestions.usages" :key="index">
              <a-row v-if="item.type === 'GAS'" :gutter="25"
                     class="w-100">
               <a-col :md="8" :xs="24">
                <a-form-item label="Omschrijving">
                 <a-input v-model:value="item.description" :disabled="true"/>
                </a-form-item>
               </a-col>
               <a-col :md="4" :xs="24">
                <a-form-item label="Type">
                 <a-input :disabled="true" :value="item.type === 'ENERGY'? 'Energie':'Gas'"/>
                </a-form-item>
               </a-col>
               <a-col :md="4" :xs="24">
                <a-form-item :name="['svmQuestions','usages', index, 'amount']"
                             :rules="{required: true,message: 'Dit veld is verplicht'}"
                             label="Aantal">
                 <a-input v-model:value="item.amount" :addon-after="item.unit"
                          :name="['svmQuestions','usages', index, 'amount']"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""
                          @input="item.usage = (item.amount * item.amountPerUnit).toFixed(0)"/>
                </a-form-item>
               </a-col>
               <a-col :md="4" :xs="24">
                <a-form-item :name="['svmQuestions','usages', index, 'usage']"
                             :rules="{required: true,message: 'Dit veld is verplicht'}"
                             label="Verbruik">
                 <a-input v-model:value="item.usage" :addon-after="item.type === 'ENERGY'? 'kWh':'m3'"
                          :name="['svmQuestions','usages', index, 'usage']"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col v-if="!item.default" :md="4" :xs="24">
                <a-form-item label=" ">
                 <sdButton class="ml-4" size="small" type="danger" @click.prevent="handleRemoveCategory(index)">
                  <sdFeatherIcons size="14" type="trash"/>
                 </sdButton>
                </a-form-item>
               </a-col>
              </a-row>
             </div>
             <a-row>
              <a-col :xs="24">
               <hr/>
              </a-col>
             </a-row>
             <a-row>
              <a-col :xs="24" class="mt-6">
               <sdButton class="ml-4" size="large" type="primary"
                         @click.prevent="visibleComponentGasAddModal = true">
                <sdFeatherIcons size="14" type="plus"/>
                Onderdeel toevoegen
               </sdButton>
              </a-col>
             </a-row>
            </sdCards>
            <sdCards v-if="order.minimisSupport !== null && order.minimisSupport === false" title="Verlichting">
             <a-row :gutter="25" class="w-100">
              <a-col :md="12" :xs="24">
               <a-form-item :name="['svmQuestions','oldLightingCount']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Oude verlichting">
                <a-input v-model:value="order.svmQuestions.oldLightingCount"
                         :addon-after="parseInt(order.oldLightingCount) > 0 ? 'lampen' : 'lamp'"
                         :name="['svmQuestions','oldLightingCount']"
                         onkeydown="if(event.key===','){event.preventDefault();}"
                         onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                         placeholder=""/>
               </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24">
               <a-form-item :name="['svmQuestions','newLightingCount']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="LED Verlichting">
                <a-input v-model:value="order.svmQuestions.newLightingCount"
                         :addon-after="parseInt(order.newLightingCount) > 0 ? 'lampen' : 'lamp'"
                         :name="['svmQuestions','newLightingCount']"
                         onkeydown="if(event.key===','){event.preventDefault();}"
                         onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                         placeholder=""/>
               </a-form-item>
              </a-col>
             </a-row>
             <a-row v-if="!lampCheck" class="w-100">
              <a-col :xs="24">
               <sdAlerts
                :closable="false"
                :description="`De totaal aantal lampen ${totalLamps}  stuk(s) komt niet overeen met de opgegeven aantal oude en led verlichting.`"
                :outlined="false"
                :showIcon="true"
                message=""
                type="warning"
               />
              </a-col>
             </a-row>
            </sdCards>
            <sdCards v-if="order.minimisSupport !== null && order.minimisSupport === false" title="Glas">
             <a-row :gutter="25" class="w-100">
              <a-col :md="12" :xs="24">
               <a-form-item :name="['svmQuestions','currentGlassType']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Uw huidige soort glas">
                <a-select v-model:value="order.svmQuestions.currentGlassType" :allowClear="true"
                          class="sDash_fullwidth-select"
                          size="large">
                 <a-select-option v-for="(item,index) in glassSVMOptions"
                                  :key="index"
                                  :name="['svmQuestions','currentGlassType']"
                                  :value="item.value">
                  {{ item.key }}
                 </a-select-option>
                </a-select>
               </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24">
               <a-form-item :name="['svmQuestions','glassSurface']"
                            :rules="{required: true,message: 'Dit veld is verplicht'}"
                            label="Glasoppervlakte">
                <a-input v-model:value="order.svmQuestions.glassSurface"
                         :name="['svmQuestions','glassSurface']"
                         addon-after="m2"
                         onkeydown="if(event.key===','){event.preventDefault();}"
                         onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                         placeholder=""/>
               </a-form-item>
              </a-col>
             </a-row>
            </sdCards>
            <a-row v-if="!svmAvailable" class="w-100">
             <a-col :xs="24">
              <sdAlerts
               :closable="false"
               :outlined="false"
               :showIcon="true"
               description="Het jaarlijks energieverbruik per bedrijfspand moet minder dan 25.000 m3 aardgas en minder dan 50.000 kWh elektriciteit zijn om te voldoen aan de minimale eisen voor een SVNM subsidie aanvraag."
               message=""
               type="warning"
              />
             </a-col>
            </a-row>
            <ActionWrapper>
             <div class="step-action-wrap">
              <div class="step-action-inner">
               <a-row>
                <a-col :xs="24">
                 <div class="steps-action">
                  <sdButton class="btn-prev" type="primary" @click.prevent="() => prev()">
                   <sdFeatherIcons size="16" type="arrow-left"/>
                   <span>Vorige</span>
                  </sdButton>
                  <a-button
                   :disabled="order.minimisSupport !== null && order.minimisSupport === true"
                   class="btn-next"
                   html-type="submit"
                   type="primary">
                   <span>Volgende</span>
                   <sdFeatherIcons size="16" type="arrow-right"/>
                  </a-button>
                 </div>
                </a-col>
               </a-row>
              </div>
             </div>
            </ActionWrapper>
           </a-form>
          </div>
         </template>
         <template #relation>
          <Relation
           ref="relationComponentRef"
           :disable-is-business="true"
           :enable-birthday="false"
           :is-order-create="true"
           :maxAge="80"
           :minAge="18"
           :relation="order.relation"
           :show-card-save-button="false"
           :show-footer="false"
           :show-global-save-button="true"
           :showHeader="false"
           type="ADD"
           @submit-external="handleRelationOnOrder($event)"
          ></Relation>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Vorige</span>
                </sdButton>
                <sdButton class="btn-next" type="primary"
                          @click.prevent="$refs.relationComponentRef.submitExternal()">
                 <span>Volgende</span>
                 <sdFeatherIcons size="16" type="arrow-right"/>
                </sdButton>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #summary>
          <Main>
           <a-row :gutter="25">
            <a-col :xs="24">
             <Suspense>
              <template #default>
               <RelationSVMSummaryCard :order="order"/>
              </template>
             </Suspense>
            </a-col>
           </a-row>
           <a-row>
            <a-form class="w-100" layout="vertical">
             <sdCards
              class="w-100" title="Huidige elektriciteit en gas situatie">
              <a-row :gutter="25" class="w-100">
               <a-col :md="6" :xs="24">
                <a-form-item label="Elektriciteitsprijs" name="energyTariff">
                 <a-input v-model:value="order.svmQuestions.energyTariff" :disabled="true" addon-after="per kWh"
                          addon-before="€"
                          name="energyTariff"
                          placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col :md="6" :xs="24">
                <a-form-item label="Gassprijs" name="gasTariff">
                 <a-input v-model:value="order.svmQuestions.gasTariff" :disabled="true" addon-after="per m3"
                          addon-before="€"
                          name="gasTariff"
                          placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col :md="6" :xs="24">
                <a-form-item label="Heeft zonnepanelen?"
                             name="hasSolar">
                 <a-select v-model:value="order.svmQuestions.hasSolar"
                           :allowClear="true"
                           :disabled="true"
                           class="sDash_fullwidth-select"
                           name="hasSolar"
                           size="large">
                  <a-select-option v-for="(item,index) in defaultOptions"
                                   :key="index"
                                   :value="item.value"
                                   name="hasSolar">
                   {{ item.key }}
                  </a-select-option>
                 </a-select>
                </a-form-item>
               </a-col>
               <a-col v-if="!order.svmQuestions.hasSolar" :md="6" :xs="24">
                <a-form-item label="Zonnepanelen mogelijk?"
                             name="solarAvailable">
                 <a-select v-model:value="order.svmQuestions.solarAvailable" :allowClear="true"
                           :disabled="true"
                           class="sDash_fullwidth-select"
                           size="large">
                  <a-select-option v-for="(item,index) in defaultOptions"
                                   :key="index"
                                   :value="item.value"
                                   name="hasSolar">
                   {{ item.key }}
                  </a-select-option>
                 </a-select>
                </a-form-item>
               </a-col>
               <a-col v-if="!order.svmQuestions.hasSolar && order.svmQuestions.solarAvailable" :md="6" :xs="24">
                <a-form-item label="Dak oppervlakte"
                             name="roofSurface">
                 <a-input v-model:value="order.svmQuestions.roofSurface"
                          :disabled="true"
                          addon-after="m2"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col :md="6" :xs="24">
                <a-form-item label="Heeft slimme thermostaat?"
                             name="hasSmartThermostat">
                 <a-select v-model:value="order.svmQuestions.hasSmartThermostat" :allowClear="true"
                           :disabled="true"
                           class="sDash_fullwidth-select"
                           size="large">
                  <a-select-option v-for="(item,index) in defaultOptions"
                                   :key="index"
                                   :value="item.value"
                                   name="hasSolar">
                   {{ item.key }}
                  </a-select-option>
                 </a-select>
                </a-form-item>
               </a-col>
              </a-row>
             </sdCards>
             <sdCards title="Energieverbruik per onderdeel">
              <div v-for="(item,index) in order.svmQuestions.usages" :key="index">
               <a-row v-if="item.type === 'ENERGY'" :gutter="25"
                      class="w-100">
                <a-col :md="8" :xs="24">
                 <a-form-item label="Omschrijving">
                  <a-input v-model:value="item.description" :disabled="true"/>
                 </a-form-item>
                </a-col>
                <a-col :md="4" :xs="24">
                 <a-form-item label="Type">
                  <a-input :disabled="true" :value="item.type === 'ENERGY'? 'Energie':'Gas'"/>
                 </a-form-item>
                </a-col>
                <a-col :md="4" :xs="24">
                 <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Aantal">
                  <a-input v-model:value="item.amount" :addon-after="item.unit"
                           :disabled="true"
                           onkeydown="if(event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder=""
                           @input="item.usage = item.amount * item.amountPerUnit"/>
                 </a-form-item>
                </a-col>
                <a-col :md="4" :xs="24">
                 <a-form-item label="Verbruik">
                  <a-input v-model:value="item.usage" :addon-after="item.type === 'ENERGY'? 'kWh':'m3'"
                           :disabled="true"/>
                 </a-form-item>
                </a-col>
               </a-row>
              </div>
             </sdCards>
             <sdCards title="Gasverbruik per onderdeel">
              <div v-for="(item,index) in order.svmQuestions.usages" :key="index">
               <a-row v-if="item.type === 'GAS'" :gutter="25"
                      class="w-100">
                <a-col :md="8" :xs="24">
                 <a-form-item label="Omschrijving">
                  <a-input v-model:value="item.description" :disabled="true"/>
                 </a-form-item>
                </a-col>
                <a-col :md="4" :xs="24">
                 <a-form-item label="Type">
                  <a-input :disabled="true" :value="item.type === 'ENERGY'? 'Energie':'Gas'"/>
                 </a-form-item>
                </a-col>
                <a-col :md="4" :xs="24">
                 <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Aantal">
                  <a-input v-model:value="item.amount" :addon-after="item.unit"
                           :disabled="true"
                           onkeydown="if(event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder=""
                           @input="item.usage = item.amount * item.amountPerUnit"/>
                 </a-form-item>
                </a-col>
                <a-col :md="4" :xs="24">
                 <a-form-item label="Verbruik">
                  <a-input v-model:value="item.usage" :addon-after="item.type === 'ENERGY'? 'kWh':'m3'"
                           :disabled="true"/>
                 </a-form-item>
                </a-col>
               </a-row>
              </div>
             </sdCards>
             <sdCards title="Verlichting">
              <a-row :gutter="25" class="w-100">
               <a-col :md="12" :xs="24">
                <a-form-item label="Oude verlichting">
                 <a-input v-model:value="order.svmQuestions.oldLightingCount"
                          :addon-after="parseInt(order.oldLightingCount) > 0 ? 'lampen' : 'lamp'"
                          :disabled="true"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col :md="12" :xs="24">
                <a-form-item label="LED Verlichting">
                 <a-input v-model:value="order.svmQuestions.newLightingCount"
                          :addon-after="parseInt(order.oldLightingCount) > 0 ? 'lampen' : 'lamp'"
                          :disabled="true"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""/>
                </a-form-item>
               </a-col>
              </a-row>
             </sdCards>
             <sdCards title="Glas">
              <a-row :gutter="25" class="w-100">
               <a-col :md="12" :xs="24">
                <a-form-item label="Uw huidige soort glas" name="currentGlassType">
                 <a-select v-model:value="order.svmQuestions.currentGlassType" :allowClear="true"
                           :disabled="true"
                           class="sDash_fullwidth-select"
                           size="large">
                  <a-select-option v-for="(item,index) in glassSVMOptions" :key="index" :value="item.value"
                                   name="currentGlassType">
                   {{ item.key }}
                  </a-select-option>
                 </a-select>
                </a-form-item>
               </a-col>
               <a-col :md="12" :xs="24">
                <a-form-item label="Glasoppervlakte"
                             name="glassSurface">
                 <a-input v-model:value="order.svmQuestions.glassSurface"
                          :disabled="true"
                          addon-after="m2"
                          name="glassSurface"
                          onkeydown="if(event.key===','){event.preventDefault();}"
                          onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                          placeholder=""/>
                </a-form-item>
               </a-col>
              </a-row>
             </sdCards>
            </a-form>
           </a-row>
           <a-row>
            <a-form ref="giftChoiceForm" :model="order" class="w-100" layout="vertical">
             <sdCards title="Maatregel cadeau">
              <a-row :gutter="25" class="w-100">
               <a-col :md="12" :xs="24">
                <a-form-item :name="['svmQuestions','giftChoice']"
                             :rules="{required: true,message: 'Dit veld is verplicht'}"
                             label="Selecteer een cadeau">
                 <a-select v-model:value="order.svmQuestions.giftChoice" :allowClear="true"
                           :name="['svmQuestions','giftChoice']"
                           class="sDash_fullwidth-select"
                           size="large">
                  <a-select-option v-for="(item,index) in giftSVMOptions" :key="index"
                                   :name="['svmQuestions','giftChoice']"
                                   :value="item.value">
                   {{ item.key }}
                  </a-select-option>
                 </a-select>
                </a-form-item>
               </a-col>
              </a-row>
             </sdCards>
            </a-form>
           </a-row>
           <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Ondertekenen">
              <a-row :gutter="25">
               <a-col :xs="12" class="signature align-center">
                <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'" :sigOption="sigState.option"
                               :w="'450px'"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                 <div role="alert">Uw handtekening is vereist</div>
                </div>
                <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                 <span>Opnieuw</span>
                </sdButton>
               </a-col>
               <a-col :xs="12" class="mb-5">
                <a-form ref="customerApprovalsForm" :model="order"
                        layout="vertical">
                 <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"
                              :name="['product','customerApprovalsValidation', index]"
                              :rules="{ validator: checkCheckBox }" class="checkbox-group"
                              label=""
                              style="padding-bottom:0px;margin-bottom:0px">
                  <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"
                              :name="['product','customerApprovalsValidation', index]">
                   {{ item }}
                  </a-checkbox>
                 </a-form-item>
                </a-form>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
          </Main>
          <ActionWrapper v-if="!isFinished">
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="light" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Terug</span>
                </sdButton>
                <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                          type="primary"
                          @click="handleCreateOrder"> Aanmaken
                </a-button>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateButtonWrapper,
 FigureWizards,
 OrderSummary,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {BasicFormWrapper, DatePickerWrapper, Main, ModalMain} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import FlowCards from "./component/FlowCards";
import {
 defaultOptions,
 giftSVMOptions,
 glassSVMOptions,
 meterTypeOptions,
 svmEnergyCategoryOptions,
 svmGasCategoryOptions,
 switchTypeOptions
} from "../../utility/enums";
import {ActionWrapper} from "../../components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "../../utility/validators";
import Aom from "../../components/aom/Aom";
import ConnectionTable from "./component/ConnectionTable";
import RelationSVMSummaryCard from "./component/RelationSVMSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRouter} from "vue-router";

const OrderCreate = {
 name: 'Wizards',
 components: {
  ConnectionTable,
  RelationSVMSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ConnectionCardWrapper,
  RelationCard,
  ActionWrapper,
  EstimateButtonWrapper,
  Aom,
  FlowCards,
  DatePickerWrapper,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 watch: {
  addressForm: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.addressForm.postalCode,
       houseNumber: this.addressForm.houseNumber,
       country: this.addressForm.country,
       type: 'SVM',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const relationComponentRef = ref();
  const houseNumberAdditionsOptions = computed(() => state.globalSearch.houseNumberAdditions);
  const globalSearchData = computed(() => state.globalSearch.data);
  const globalSearchLoading = computed(() => state.globalSearch.loadingSearch);
  const disabledGlobalSearchButton = computed(() => state.globalSearch.disabledGlobalSearchButton);
  const order = reactive(computed(() => state.svm.order));
  const loadingCreateOrder = computed(() => state.svm.loadingCreateOrder);
  const loadingPreFillRelationOnOrder = computed(() => state.order.loadingPreFillRelationOnOrder);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const customerApprovalsForm = ref();
  const giftChoiceForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const visibleComponentEnergyAddModal = ref(false);
  const visibleComponentGasAddModal = ref(false);
  let svmAvailable = ref(true);
  let lampCheck = ref(true);
  let totalLamps = ref(0);
  const selectedComponent = reactive({
   selectedComponent: null,
  });

  const rulesAddComponent = {
   selectedComponent: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'SVM',
   });
  };

  const handleSelectRelation = (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }
   dispatch('preFillRelationOnSVMOrder', {value: data, stepRoute: next});
  };

  const handleRelationOnOrder = (data) => {
   dispatch('addRelationToSVMOrder', {value: data, stepRoute: next})
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleRemoveCategory = (index) => {
   dispatch('removeSVMComponent', index)
  };

  const handleComponentEnergyAddModalCancel = () => {
   visibleComponentEnergyAddModal.value = false;
  };

  const handleComponentGasAddModalCancel = () => {
   visibleComponentGasAddModal.value = false;
  };

  const handleAddComponent = async () => {
   await dispatch('addSVMComponent', selectedComponent.selectedComponent);
   handleComponentEnergyAddModalCancel();
   handleComponentGasAddModalCancel();
   selectedComponent.selectedComponent = null;
  };

  const handleSaveAnalyze = () => {
   svmAvailable.value = true;
   lampCheck.value = true;
   let totalUsageM3 = 0;
   let totalUsageKwh = 0;
   totalLamps.value = 0;
   order.value.svmQuestions.usages.forEach((item) => {
    if (item.type === 'GAS') {
     totalUsageM3 += parseInt(item.usage);
    }
    if (item.type === 'ENERGY') {
     totalUsageKwh += parseInt(item.usage);
    }
    if (item.id === 'LIGHTING') {
     totalLamps.value = item.amount;
    }
   });

   if (parseInt(totalLamps.value) !== (parseInt(order.value.svmQuestions.oldLightingCount) + parseInt(order.value.svmQuestions.newLightingCount))) {
    lampCheck.value = false;
   }

   if (totalUsageM3 < 25000 && totalUsageKwh < 50000 && lampCheck.value) {
    svmAvailable.value = true;
    next();
   } else if (lampCheck.value) {
    svmAvailable.value = false;
   }
  };

  const handleCreateOrder = () => {
   const orderValidators = [giftChoiceForm.value.validate()]
   signatureError.value = false;
   if (order.value.signatureType === 'SIGNATURE') {
    orderValidators.push(customerApprovalsForm.value.validate());
   }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureSVMData', signature1.value.save('image/svg+xml'))
      dispatch('createSVMOrder', {successRoute: orderCreateSuccessRoute})
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createSVMOrder', {successRoute: orderCreateSuccessRoute})
    }
   })
    .catch(() => dispatch('orderCreateValidationError'))
  };

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  onMounted(async () => {
   await dispatch('resetSVMOrder');
   await dispatch("cleanGlobalSearch");
  });

  return {
   sigState,
   steps: [
    {
     title: "Adres",
     content: "address",
    },
    {
     title: "Analyze",
     content: "analyze",
    },
    {
     title: "Klantgegevens",
     content: "relation",
    },
    {
     title: "Overzicht",
     content: "summary",
    },
   ],
   clearSignature,
   signature1,
   addressForm,
   addressConnectionForm,
   houseNumberAdditionsOptions,
   addressFormRules,
   globalSearchData,
   order,
   globalSearchLoading,
   disabledGlobalSearchButton,
   relationComponentRef,
   switchTypeOptions,
   meterTypeOptions,
   defaultOptions,
   handleSelectRelation,
   globalSearch,
   done,
   prev,
   next,
   handleRelationOnOrder,
   handleCreateOrder,
   isFinished,
   current,
   onHandleChange,
   onShowSizeChange,
   pageSize,
   form,
   moment,
   loadingPreFillRelationOnOrder,
   prevEan,
   customerApprovalsForm,
   checkCheckBox,
   signatureError,
   loadingCreateOrder,
   loadingHouseNumberAdditions,
   handleRemoveCategory,
   handleComponentEnergyAddModalCancel,
   visibleComponentEnergyAddModal,
   svmEnergyCategoryOptions,
   selectedComponent,
   handleAddComponent,
   glassSVMOptions,
   handleSaveAnalyze,
   rulesAddComponent,
   giftSVMOptions,
   giftChoiceForm,
   svmAvailable,
   lampCheck,
   totalLamps,
   svmGasCategoryOptions,
   handleComponentGasAddModalCancel,
   visibleComponentGasAddModal,
  };
 },
};

export default OrderCreate;
</script>
<style>
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}
</style>
